export const work = [
  {
    id: 80,
    description: '(30) Modules on, and powered up @ Browns Point',
    location: 'Yarmouth, Maine',
    published_at: '2022-07-27T19:26:57.250Z',
    created_at: '2022-05-28T18:27:41.545Z',
    updated_at: '2022-07-27T19:26:57.266Z',
    Image: {
      id: 99,
      name: 'IMG_20220406_172123.jpg',
      alternativeText: '',
      caption: '',
      width: 3120,
      height: 4160,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1653762191/large_IMG_20220406_172123_694de1ddee.jpg',
          hash: 'large_IMG_20220406_172123_694de1ddee',
          mime: 'image/jpeg',
          name: 'large_IMG_20220406_172123.jpg',
          path: null,
          size: 172.17,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_IMG_20220406_172123_694de1ddee',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1653762192/small_IMG_20220406_172123_694de1ddee.jpg',
          hash: 'small_IMG_20220406_172123_694de1ddee',
          mime: 'image/jpeg',
          name: 'small_IMG_20220406_172123.jpg',
          path: null,
          size: 46.44,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_IMG_20220406_172123_694de1ddee',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1653762192/medium_IMG_20220406_172123_694de1ddee.jpg',
          hash: 'medium_IMG_20220406_172123_694de1ddee',
          mime: 'image/jpeg',
          name: 'medium_IMG_20220406_172123.jpg',
          path: null,
          size: 102.89,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_IMG_20220406_172123_694de1ddee',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1653762191/thumbnail_IMG_20220406_172123_694de1ddee.jpg',
          hash: 'thumbnail_IMG_20220406_172123_694de1ddee',
          mime: 'image/jpeg',
          name: 'thumbnail_IMG_20220406_172123.jpg',
          path: null,
          size: 5.81,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_IMG_20220406_172123_694de1ddee',
            resource_type: 'image',
          },
        },
      },
      hash: 'IMG_20220406_172123_694de1ddee',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 2325.69,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1653762190/IMG_20220406_172123_694de1ddee.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'IMG_20220406_172123_694de1ddee',
        resource_type: 'image',
      },
      created_at: '2022-05-28T18:23:12.711Z',
      updated_at: '2022-05-28T18:23:12.723Z',
    },
  },
  {
    id: 79,
    description: 'Attachments in on Browns Point...',
    location: 'Yarmouth, Maine',
    published_at: '2022-07-27T19:27:02.777Z',
    created_at: '2022-05-28T18:26:27.806Z',
    updated_at: '2022-07-27T19:27:02.798Z',
    Image: {
      id: 100,
      name: 'IMG_20220406_094436.jpg',
      alternativeText: '',
      caption: '',
      width: 3120,
      height: 4160,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1653762193/large_IMG_20220406_094436_8eba133333.jpg',
          hash: 'large_IMG_20220406_094436_8eba133333',
          mime: 'image/jpeg',
          name: 'large_IMG_20220406_094436.jpg',
          path: null,
          size: 175.4,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_IMG_20220406_094436_8eba133333',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1653762194/small_IMG_20220406_094436_8eba133333.jpg',
          hash: 'small_IMG_20220406_094436_8eba133333',
          mime: 'image/jpeg',
          name: 'small_IMG_20220406_094436.jpg',
          path: null,
          size: 44.01,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_IMG_20220406_094436_8eba133333',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1653762193/medium_IMG_20220406_094436_8eba133333.jpg',
          hash: 'medium_IMG_20220406_094436_8eba133333',
          mime: 'image/jpeg',
          name: 'medium_IMG_20220406_094436.jpg',
          path: null,
          size: 101.08,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_IMG_20220406_094436_8eba133333',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1653762193/thumbnail_IMG_20220406_094436_8eba133333.jpg',
          hash: 'thumbnail_IMG_20220406_094436_8eba133333',
          mime: 'image/jpeg',
          name: 'thumbnail_IMG_20220406_094436.jpg',
          path: null,
          size: 5.26,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_IMG_20220406_094436_8eba133333',
            resource_type: 'image',
          },
        },
      },
      hash: 'IMG_20220406_094436_8eba133333',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 3034.25,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1653762192/IMG_20220406_094436_8eba133333.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'IMG_20220406_094436_8eba133333',
        resource_type: 'image',
      },
      created_at: '2022-05-28T18:23:14.546Z',
      updated_at: '2022-05-28T18:23:14.557Z',
    },
  },
  {
    id: 78,
    description:
      'Mr Jackson with a new addition to his property in Yarmouth!',
    location: 'Yarmouth, Maine',
    published_at: '2022-07-27T19:27:11.993Z',
    created_at: '2022-05-28T18:20:45.593Z',
    updated_at: '2022-07-27T19:27:12.008Z',
    Image: {
      id: 98,
      name: 'IMG_20220406_114135.jpg',
      alternativeText: '',
      caption:
        'Thank you for being such huge conservation advocates for so many years, and thanks for being our customer Mr & Mrs. Jackson!',
      width: 3120,
      height: 4160,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1653761987/large_IMG_20220406_114135_0cd84e17a8.jpg',
          hash: 'large_IMG_20220406_114135_0cd84e17a8',
          mime: 'image/jpeg',
          name: 'large_IMG_20220406_114135.jpg',
          path: null,
          size: 192.42,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_IMG_20220406_114135_0cd84e17a8',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1653761988/small_IMG_20220406_114135_0cd84e17a8.jpg',
          hash: 'small_IMG_20220406_114135_0cd84e17a8',
          mime: 'image/jpeg',
          name: 'small_IMG_20220406_114135.jpg',
          path: null,
          size: 52.85,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_IMG_20220406_114135_0cd84e17a8',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1653761987/medium_IMG_20220406_114135_0cd84e17a8.jpg',
          hash: 'medium_IMG_20220406_114135_0cd84e17a8',
          mime: 'image/jpeg',
          name: 'medium_IMG_20220406_114135.jpg',
          path: null,
          size: 115.85,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_IMG_20220406_114135_0cd84e17a8',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1653761986/thumbnail_IMG_20220406_114135_0cd84e17a8.jpg',
          hash: 'thumbnail_IMG_20220406_114135_0cd84e17a8',
          mime: 'image/jpeg',
          name: 'thumbnail_IMG_20220406_114135.jpg',
          path: null,
          size: 6.4,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_IMG_20220406_114135_0cd84e17a8',
            resource_type: 'image',
          },
        },
      },
      hash: 'IMG_20220406_114135_0cd84e17a8',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 2405.3,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1653761986/IMG_20220406_114135_0cd84e17a8.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'IMG_20220406_114135_0cd84e17a8',
        resource_type: 'image',
      },
      created_at: '2022-05-28T18:19:48.648Z',
      updated_at: '2022-05-28T18:19:48.665Z',
    },
  },
  {
    id: 81,
    description:
      'Richard and Marilyn are using more power with their new heat pumps, so we tacked on (9) more modules to match their needs!',
    location: 'Wells, Maine',
    published_at: '2022-07-27T19:27:16.716Z',
    created_at: '2022-05-28T18:38:20.195Z',
    updated_at: '2022-07-27T19:27:16.799Z',
    Image: {
      id: 101,
      name: 'Littlefield Road in Twilight',
      alternativeText: 'Littlefield Road in Twilight',
      caption:
        'Richard and Marilyn are using more power with their new heat pumps, so we tacked on (9) more modules to match their needs!',
      width: 3120,
      height: 4160,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1653763038/large_IMG_20220425_190923_39aab46191.jpg',
          hash: 'large_IMG_20220425_190923_39aab46191',
          mime: 'image/jpeg',
          name: 'large_IMG_20220425_190923.jpg',
          path: null,
          size: 156.06,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_IMG_20220425_190923_39aab46191',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1653763039/small_IMG_20220425_190923_39aab46191.jpg',
          hash: 'small_IMG_20220425_190923_39aab46191',
          mime: 'image/jpeg',
          name: 'small_IMG_20220425_190923.jpg',
          path: null,
          size: 44.3,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_IMG_20220425_190923_39aab46191',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1653763038/medium_IMG_20220425_190923_39aab46191.jpg',
          hash: 'medium_IMG_20220425_190923_39aab46191',
          mime: 'image/jpeg',
          name: 'medium_IMG_20220425_190923.jpg',
          path: null,
          size: 95.18,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_IMG_20220425_190923_39aab46191',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1653763037/thumbnail_IMG_20220425_190923_39aab46191.jpg',
          hash: 'thumbnail_IMG_20220425_190923_39aab46191',
          mime: 'image/jpeg',
          name: 'thumbnail_IMG_20220425_190923.jpg',
          path: null,
          size: 5.65,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_IMG_20220425_190923_39aab46191',
            resource_type: 'image',
          },
        },
      },
      hash: 'IMG_20220425_190923_39aab46191',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 1894.68,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1653763037/IMG_20220425_190923_39aab46191.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'IMG_20220425_190923_39aab46191',
        resource_type: 'image',
      },
      created_at: '2022-05-28T18:37:19.262Z',
      updated_at: '2022-05-28T18:37:59.072Z',
    },
  },
  {
    id: 87,
    description: 'Site Visit in Norway',
    location: 'Norway, Maine',
    published_at: '2023-01-20T16:32:00.348Z',
    created_at: '2023-01-20T16:27:14.991Z',
    updated_at: '2023-01-20T16:32:00.365Z',
    Image: {
      id: 110,
      name: 'Site Visit in Norway',
      alternativeText: 'Site Visit in Norway',
      caption: 'Site Visit in Norway',
      width: 1200,
      height: 1600,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231942/large_Site_Visit_in_Norway_d70aeb4031.jpg',
          hash: 'large_Site_Visit_in_Norway_d70aeb4031',
          mime: 'image/jpeg',
          name: 'large_Site Visit in Norway',
          path: null,
          size: 270.16,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_Site_Visit_in_Norway_d70aeb4031',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231943/small_Site_Visit_in_Norway_d70aeb4031.jpg',
          hash: 'small_Site_Visit_in_Norway_d70aeb4031',
          mime: 'image/jpeg',
          name: 'small_Site Visit in Norway',
          path: null,
          size: 74.97,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_Site_Visit_in_Norway_d70aeb4031',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231943/medium_Site_Visit_in_Norway_d70aeb4031.jpg',
          hash: 'medium_Site_Visit_in_Norway_d70aeb4031',
          mime: 'image/jpeg',
          name: 'medium_Site Visit in Norway',
          path: null,
          size: 155.23,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_Site_Visit_in_Norway_d70aeb4031',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231942/thumbnail_Site_Visit_in_Norway_d70aeb4031.jpg',
          hash: 'thumbnail_Site_Visit_in_Norway_d70aeb4031',
          mime: 'image/jpeg',
          name: 'thumbnail_Site Visit in Norway',
          path: null,
          size: 8.07,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_Site_Visit_in_Norway_d70aeb4031',
            resource_type: 'image',
          },
        },
      },
      hash: 'Site_Visit_in_Norway_d70aeb4031',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 630.03,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231941/Site_Visit_in_Norway_d70aeb4031.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'Site_Visit_in_Norway_d70aeb4031',
        resource_type: 'image',
      },
      created_at: '2023-01-20T16:25:43.908Z',
      updated_at: '2023-01-20T16:25:43.919Z',
    },
  },
  {
    id: 88,
    description: 'Site Visit in Norway 2',
    location: 'Norway, Maine',
    published_at: '2023-01-20T16:32:06.364Z',
    created_at: '2023-01-20T16:27:46.884Z',
    updated_at: '2023-01-20T16:32:06.382Z',
    Image: {
      id: 113,
      name: 'Site Visit in Norway 2',
      alternativeText: 'Site Visit in Norway 2',
      caption: 'Site Visit in Norway 2',
      width: 1600,
      height: 1200,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231943/large_Site_Visit_in_Norway_2_417ad4ff21.jpg',
          hash: 'large_Site_Visit_in_Norway_2_417ad4ff21',
          mime: 'image/jpeg',
          name: 'large_Site Visit in Norway 2',
          path: null,
          size: 223.99,
          width: 1000,
          height: 750,
          provider_metadata: {
            public_id: 'large_Site_Visit_in_Norway_2_417ad4ff21',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231943/small_Site_Visit_in_Norway_2_417ad4ff21.jpg',
          hash: 'small_Site_Visit_in_Norway_2_417ad4ff21',
          mime: 'image/jpeg',
          name: 'small_Site Visit in Norway 2',
          path: null,
          size: 60.33,
          width: 500,
          height: 375,
          provider_metadata: {
            public_id: 'small_Site_Visit_in_Norway_2_417ad4ff21',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231943/medium_Site_Visit_in_Norway_2_417ad4ff21.jpg',
          hash: 'medium_Site_Visit_in_Norway_2_417ad4ff21',
          mime: 'image/jpeg',
          name: 'medium_Site Visit in Norway 2',
          path: null,
          size: 126.7,
          width: 750,
          height: 563,
          provider_metadata: {
            public_id: 'medium_Site_Visit_in_Norway_2_417ad4ff21',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231942/thumbnail_Site_Visit_in_Norway_2_417ad4ff21.jpg',
          hash: 'thumbnail_Site_Visit_in_Norway_2_417ad4ff21',
          mime: 'image/jpeg',
          name: 'thumbnail_Site Visit in Norway 2',
          path: null,
          size: 11.65,
          width: 208,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_Site_Visit_in_Norway_2_417ad4ff21',
            resource_type: 'image',
          },
        },
      },
      hash: 'Site_Visit_in_Norway_2_417ad4ff21',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 556.55,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231941/Site_Visit_in_Norway_2_417ad4ff21.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'Site_Visit_in_Norway_2_417ad4ff21',
        resource_type: 'image',
      },
      created_at: '2023-01-20T16:25:44.259Z',
      updated_at: '2023-01-20T16:25:44.266Z',
    },
  },
  {
    id: 1,
    description: 'Gorham project',
    location: 'Gorham',
    published_at: '2021-01-06T22:44:56.035Z',
    created_at: '2021-01-06T22:18:46.030Z',
    updated_at: '2021-01-06T22:44:56.149Z',
    Image: {
      id: 7,
      name: 'gorham.jpg',
      alternativeText: '',
      caption: '',
      width: 1080,
      height: 1080,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971470/large_gorham_e4f363a9e3.jpg',
          hash: 'large_gorham_e4f363a9e3',
          mime: 'image/jpeg',
          name: 'large_gorham.jpg',
          path: null,
          size: 163.31,
          width: 1000,
          height: 1000,
          provider_metadata: {
            public_id: 'large_gorham_e4f363a9e3',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971471/small_gorham_e4f363a9e3.jpg',
          hash: 'small_gorham_e4f363a9e3',
          mime: 'image/jpeg',
          name: 'small_gorham.jpg',
          path: null,
          size: 49.29,
          width: 500,
          height: 500,
          provider_metadata: {
            public_id: 'small_gorham_e4f363a9e3',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971470/medium_gorham_e4f363a9e3.jpg',
          hash: 'medium_gorham_e4f363a9e3',
          mime: 'image/jpeg',
          name: 'medium_gorham.jpg',
          path: null,
          size: 102.6,
          width: 750,
          height: 750,
          provider_metadata: {
            public_id: 'medium_gorham_e4f363a9e3',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971469/thumbnail_gorham_e4f363a9e3.jpg',
          hash: 'thumbnail_gorham_e4f363a9e3',
          mime: 'image/jpeg',
          name: 'thumbnail_gorham.jpg',
          path: null,
          size: 6.58,
          width: 156,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_gorham_e4f363a9e3',
            resource_type: 'image',
          },
        },
      },
      hash: 'gorham_e4f363a9e3',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 123.83,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971468/gorham_e4f363a9e3.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'gorham_e4f363a9e3',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:17:51.699Z',
      updated_at: '2021-01-06T22:17:51.853Z',
    },
  },
  {
    id: 2,
    description: 'Gorham Project',
    location: 'Gorham',
    published_at: '2021-01-06T22:45:00.989Z',
    created_at: '2021-01-06T22:19:13.195Z',
    updated_at: '2021-01-06T22:45:01.097Z',
    Image: {
      id: 12,
      name: 'gorham2.jpg',
      alternativeText: '',
      caption: '',
      width: 3072,
      height: 4096,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971473/large_gorham2_9c2566a3d7.jpg',
          hash: 'large_gorham2_9c2566a3d7',
          mime: 'image/jpeg',
          name: 'large_gorham2.jpg',
          path: null,
          size: 106.36,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_gorham2_9c2566a3d7',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971474/small_gorham2_9c2566a3d7.jpg',
          hash: 'small_gorham2_9c2566a3d7',
          mime: 'image/jpeg',
          name: 'small_gorham2.jpg',
          path: null,
          size: 31.59,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_gorham2_9c2566a3d7',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971474/medium_gorham2_9c2566a3d7.jpg',
          hash: 'medium_gorham2_9c2566a3d7',
          mime: 'image/jpeg',
          name: 'medium_gorham2.jpg',
          path: null,
          size: 64.97,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_gorham2_9c2566a3d7',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971473/thumbnail_gorham2_9c2566a3d7.jpg',
          hash: 'thumbnail_gorham2_9c2566a3d7',
          mime: 'image/jpeg',
          name: 'thumbnail_gorham2.jpg',
          path: null,
          size: 4.47,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_gorham2_9c2566a3d7',
            resource_type: 'image',
          },
        },
      },
      hash: 'gorham2_9c2566a3d7',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 928.43,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971471/gorham2_9c2566a3d7.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'gorham2_9c2566a3d7',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:17:54.693Z',
      updated_at: '2021-01-06T22:17:54.709Z',
    },
  },
  {
    id: 3,
    description: 'Panels going up!',
    location: 'Gorham',
    published_at: '2021-01-06T22:45:30.283Z',
    created_at: '2021-01-06T22:19:49.658Z',
    updated_at: '2021-01-06T22:45:30.333Z',
    Image: {
      id: 6,
      name: 'gorham3.jpg',
      alternativeText: '',
      caption: '',
      width: 600,
      height: 800,
      formats: {
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971468/small_gorham3_b3798280cb.jpg',
          hash: 'small_gorham3_b3798280cb',
          mime: 'image/jpeg',
          name: 'small_gorham3.jpg',
          path: null,
          size: 32.49,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_gorham3_b3798280cb',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971468/medium_gorham3_b3798280cb.jpg',
          hash: 'medium_gorham3_b3798280cb',
          mime: 'image/jpeg',
          name: 'medium_gorham3.jpg',
          path: null,
          size: 63.6,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_gorham3_b3798280cb',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971467/thumbnail_gorham3_b3798280cb.jpg',
          hash: 'thumbnail_gorham3_b3798280cb',
          mime: 'image/jpeg',
          name: 'thumbnail_gorham3.jpg',
          path: null,
          size: 4.58,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_gorham3_b3798280cb',
            resource_type: 'image',
          },
        },
      },
      hash: 'gorham3_b3798280cb',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 53.46,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971467/gorham3_b3798280cb.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'gorham3_b3798280cb',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:17:49.193Z',
      updated_at: '2021-01-06T22:17:49.353Z',
    },
  },
  {
    id: 4,
    description: 'Finished product',
    location: 'Gorham',
    published_at: '2021-01-06T22:45:32.829Z',
    created_at: '2021-01-06T22:20:26.666Z',
    updated_at: '2021-01-06T22:45:32.860Z',
    Image: {
      id: 9,
      name: 'gorham4.jpg',
      alternativeText: '',
      caption: '',
      width: 3072,
      height: 4096,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971472/large_gorham4_845895dbd7.jpg',
          hash: 'large_gorham4_845895dbd7',
          mime: 'image/jpeg',
          name: 'large_gorham4.jpg',
          path: null,
          size: 133.8,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_gorham4_845895dbd7',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971473/small_gorham4_845895dbd7.jpg',
          hash: 'small_gorham4_845895dbd7',
          mime: 'image/jpeg',
          name: 'small_gorham4.jpg',
          path: null,
          size: 35.48,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_gorham4_845895dbd7',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971472/medium_gorham4_845895dbd7.jpg',
          hash: 'medium_gorham4_845895dbd7',
          mime: 'image/jpeg',
          name: 'medium_gorham4.jpg',
          path: null,
          size: 78.11,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_gorham4_845895dbd7',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971471/thumbnail_gorham4_845895dbd7.jpg',
          hash: 'thumbnail_gorham4_845895dbd7',
          mime: 'image/jpeg',
          name: 'thumbnail_gorham4.jpg',
          path: null,
          size: 4.6,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_gorham4_845895dbd7',
            resource_type: 'image',
          },
        },
      },
      hash: 'gorham4_845895dbd7',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 1191.27,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971469/gorham4_845895dbd7.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'gorham4_845895dbd7',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:17:53.677Z',
      updated_at: '2021-01-06T22:17:53.725Z',
    },
  },
  {
    id: 5,
    description: 'Portland project',
    location: 'Portland',
    published_at: '2021-01-06T22:45:35.336Z',
    created_at: '2021-01-06T22:21:07.370Z',
    updated_at: '2021-01-06T22:45:35.365Z',
    Image: {
      id: 13,
      name: 'portland.jpg',
      alternativeText: '',
      caption: '',
      width: 3072,
      height: 4096,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971473/large_portland_51e215bb8d.jpg',
          hash: 'large_portland_51e215bb8d',
          mime: 'image/jpeg',
          name: 'large_portland.jpg',
          path: null,
          size: 181.01,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_portland_51e215bb8d',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971474/small_portland_51e215bb8d.jpg',
          hash: 'small_portland_51e215bb8d',
          mime: 'image/jpeg',
          name: 'small_portland.jpg',
          path: null,
          size: 47.9,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_portland_51e215bb8d',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971474/medium_portland_51e215bb8d.jpg',
          hash: 'medium_portland_51e215bb8d',
          mime: 'image/jpeg',
          name: 'medium_portland.jpg',
          path: null,
          size: 106.56,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_portland_51e215bb8d',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971473/thumbnail_portland_51e215bb8d.jpg',
          hash: 'thumbnail_portland_51e215bb8d',
          mime: 'image/jpeg',
          name: 'thumbnail_portland.jpg',
          path: null,
          size: 5.96,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_portland_51e215bb8d',
            resource_type: 'image',
          },
        },
      },
      hash: 'portland_51e215bb8d',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 1629.74,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971472/portland_51e215bb8d.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'portland_51e215bb8d',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:17:54.910Z',
      updated_at: '2021-01-06T22:17:55.041Z',
    },
  },
  {
    id: 6,
    description: 'Portland from above',
    location: 'Portland',
    published_at: '2021-01-06T22:45:37.820Z',
    created_at: '2021-01-06T22:21:30.961Z',
    updated_at: '2021-01-06T22:45:37.849Z',
    Image: {
      id: 16,
      name: 'portland2.jpg',
      alternativeText: '',
      caption: '',
      width: 3072,
      height: 4096,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971475/large_portland2_d969e0ed7b.jpg',
          hash: 'large_portland2_d969e0ed7b',
          mime: 'image/jpeg',
          name: 'large_portland2.jpg',
          path: null,
          size: 252.64,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_portland2_d969e0ed7b',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971476/small_portland2_d969e0ed7b.jpg',
          hash: 'small_portland2_d969e0ed7b',
          mime: 'image/jpeg',
          name: 'small_portland2.jpg',
          path: null,
          size: 62.73,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_portland2_d969e0ed7b',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971475/medium_portland2_d969e0ed7b.jpg',
          hash: 'medium_portland2_d969e0ed7b',
          mime: 'image/jpeg',
          name: 'medium_portland2.jpg',
          path: null,
          size: 144.09,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_portland2_d969e0ed7b',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971474/thumbnail_portland2_d969e0ed7b.jpg',
          hash: 'thumbnail_portland2_d969e0ed7b',
          mime: 'image/jpeg',
          name: 'thumbnail_portland2.jpg',
          path: null,
          size: 6.93,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_portland2_d969e0ed7b',
            resource_type: 'image',
          },
        },
      },
      hash: 'portland2_d969e0ed7b',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 2464.2,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971474/portland2_d969e0ed7b.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'portland2_d969e0ed7b',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:17:56.776Z',
      updated_at: '2021-01-06T22:17:57.445Z',
    },
  },
  {
    id: 7,
    description: 'Before...',
    location: 'Yarmouth',
    published_at: '2021-01-06T22:45:40.476Z',
    created_at: '2021-01-06T22:22:32.052Z',
    updated_at: '2021-01-06T22:45:40.535Z',
    Image: {
      id: 21,
      name: 'yarmouth.jpg',
      alternativeText: '',
      caption: '',
      width: 3072,
      height: 4096,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971482/large_yarmouth_772f00f43b.jpg',
          hash: 'large_yarmouth_772f00f43b',
          mime: 'image/jpeg',
          name: 'large_yarmouth.jpg',
          path: null,
          size: 162.05,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_yarmouth_772f00f43b',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971483/small_yarmouth_772f00f43b.jpg',
          hash: 'small_yarmouth_772f00f43b',
          mime: 'image/jpeg',
          name: 'small_yarmouth.jpg',
          path: null,
          size: 41.97,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_yarmouth_772f00f43b',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971483/medium_yarmouth_772f00f43b.jpg',
          hash: 'medium_yarmouth_772f00f43b',
          mime: 'image/jpeg',
          name: 'medium_yarmouth.jpg',
          path: null,
          size: 94.01,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_yarmouth_772f00f43b',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971482/thumbnail_yarmouth_772f00f43b.jpg',
          hash: 'thumbnail_yarmouth_772f00f43b',
          mime: 'image/jpeg',
          name: 'thumbnail_yarmouth.jpg',
          path: null,
          size: 5.61,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_yarmouth_772f00f43b',
            resource_type: 'image',
          },
        },
      },
      hash: 'yarmouth_772f00f43b',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 1483.69,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971481/yarmouth_772f00f43b.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'yarmouth_772f00f43b',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:18:04.205Z',
      updated_at: '2021-01-06T22:18:04.239Z',
    },
  },
  {
    id: 8,
    description: 'In progress Yarmouth',
    location: 'Yarmouth',
    published_at: '2021-01-06T22:45:43.052Z',
    created_at: '2021-01-06T22:22:48.947Z',
    updated_at: '2021-01-06T22:45:43.093Z',
    Image: {
      id: 23,
      name: 'yarmouth2.jpg',
      alternativeText: '',
      caption: '',
      width: 3072,
      height: 4096,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971483/large_yarmouth2_a70c3cbeec.jpg',
          hash: 'large_yarmouth2_a70c3cbeec',
          mime: 'image/jpeg',
          name: 'large_yarmouth2.jpg',
          path: null,
          size: 177.82,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_yarmouth2_a70c3cbeec',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971484/small_yarmouth2_a70c3cbeec.jpg',
          hash: 'small_yarmouth2_a70c3cbeec',
          mime: 'image/jpeg',
          name: 'small_yarmouth2.jpg',
          path: null,
          size: 43.9,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_yarmouth2_a70c3cbeec',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971483/medium_yarmouth2_a70c3cbeec.jpg',
          hash: 'medium_yarmouth2_a70c3cbeec',
          mime: 'image/jpeg',
          name: 'medium_yarmouth2.jpg',
          path: null,
          size: 101.32,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_yarmouth2_a70c3cbeec',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971482/thumbnail_yarmouth2_a70c3cbeec.jpg',
          hash: 'thumbnail_yarmouth2_a70c3cbeec',
          mime: 'image/jpeg',
          name: 'thumbnail_yarmouth2.jpg',
          path: null,
          size: 5.24,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_yarmouth2_a70c3cbeec',
            resource_type: 'image',
          },
        },
      },
      hash: 'yarmouth2_a70c3cbeec',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 1664.35,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971482/yarmouth2_a70c3cbeec.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'yarmouth2_a70c3cbeec',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:18:04.636Z',
      updated_at: '2021-01-06T22:18:04.659Z',
    },
  },
  {
    id: 9,
    description: 'Top of the world!',
    location: 'Yarmouth',
    published_at: '2021-01-06T22:45:46.230Z',
    created_at: '2021-01-06T22:23:23.916Z',
    updated_at: '2021-01-06T22:45:46.301Z',
    Image: {
      id: 22,
      name: 'yarmouth3.jpg',
      alternativeText: '',
      caption: '',
      width: 5840,
      height: 1792,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971483/large_yarmouth3_3dc816fc5a.jpg',
          hash: 'large_yarmouth3_3dc816fc5a',
          mime: 'image/jpeg',
          name: 'large_yarmouth3.jpg',
          path: null,
          size: 63.94,
          width: 1000,
          height: 307,
          provider_metadata: {
            public_id: 'large_yarmouth3_3dc816fc5a',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971484/small_yarmouth3_3dc816fc5a.jpg',
          hash: 'small_yarmouth3_3dc816fc5a',
          mime: 'image/jpeg',
          name: 'small_yarmouth3.jpg',
          path: null,
          size: 17.42,
          width: 500,
          height: 153,
          provider_metadata: {
            public_id: 'small_yarmouth3_3dc816fc5a',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971483/medium_yarmouth3_3dc816fc5a.jpg',
          hash: 'medium_yarmouth3_3dc816fc5a',
          mime: 'image/jpeg',
          name: 'medium_yarmouth3.jpg',
          path: null,
          size: 36.67,
          width: 750,
          height: 230,
          provider_metadata: {
            public_id: 'medium_yarmouth3_3dc816fc5a',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971482/thumbnail_yarmouth3_3dc816fc5a.jpg',
          hash: 'thumbnail_yarmouth3_3dc816fc5a',
          mime: 'image/jpeg',
          name: 'thumbnail_yarmouth3.jpg',
          path: null,
          size: 5.25,
          width: 245,
          height: 75,
          provider_metadata: {
            public_id: 'thumbnail_yarmouth3_3dc816fc5a',
            resource_type: 'image',
          },
        },
      },
      hash: 'yarmouth3_3dc816fc5a',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 1354.72,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971482/yarmouth3_3dc816fc5a.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'yarmouth3_3dc816fc5a',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:18:04.500Z',
      updated_at: '2021-01-06T22:18:04.548Z',
    },
  },
  {
    id: 10,
    description: 'Street view ',
    location: 'Yarmouth',
    published_at: '2021-01-06T22:45:48.990Z',
    created_at: '2021-01-06T22:23:54.129Z',
    updated_at: '2021-01-06T22:45:49.019Z',
    Image: {
      id: 20,
      name: 'yarmouth4.jpg',
      alternativeText: '',
      caption: '',
      width: 3068,
      height: 1712,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971481/large_yarmouth4_2c2cad298d.jpg',
          hash: 'large_yarmouth4_2c2cad298d',
          mime: 'image/jpeg',
          name: 'large_yarmouth4.jpg',
          path: null,
          size: 127.51,
          width: 1000,
          height: 558,
          provider_metadata: {
            public_id: 'large_yarmouth4_2c2cad298d',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971482/small_yarmouth4_2c2cad298d.jpg',
          hash: 'small_yarmouth4_2c2cad298d',
          mime: 'image/jpeg',
          name: 'small_yarmouth4.jpg',
          path: null,
          size: 36.44,
          width: 500,
          height: 279,
          provider_metadata: {
            public_id: 'small_yarmouth4_2c2cad298d',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971481/medium_yarmouth4_2c2cad298d.jpg',
          hash: 'medium_yarmouth4_2c2cad298d',
          mime: 'image/jpeg',
          name: 'medium_yarmouth4.jpg',
          path: null,
          size: 74.55,
          width: 750,
          height: 419,
          provider_metadata: {
            public_id: 'medium_yarmouth4_2c2cad298d',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971480/thumbnail_yarmouth4_2c2cad298d.jpg',
          hash: 'thumbnail_yarmouth4_2c2cad298d',
          mime: 'image/jpeg',
          name: 'thumbnail_yarmouth4.jpg',
          path: null,
          size: 10.25,
          width: 245,
          height: 137,
          provider_metadata: {
            public_id: 'thumbnail_yarmouth4_2c2cad298d',
            resource_type: 'image',
          },
        },
      },
      hash: 'yarmouth4_2c2cad298d',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 587.9,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971477/yarmouth4_2c2cad298d.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'yarmouth4_2c2cad298d',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:18:02.481Z',
      updated_at: '2021-01-06T22:18:02.534Z',
    },
  },
  {
    id: 11,
    description: 'After!',
    location: 'Yarmouth',
    published_at: '2021-01-06T22:45:53.217Z',
    created_at: '2021-01-06T22:24:18.938Z',
    updated_at: '2021-01-06T22:45:53.257Z',
    Image: {
      id: 18,
      name: 'yarmouth5.jpg',
      alternativeText: '',
      caption: '',
      width: 3072,
      height: 2944,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971476/large_yarmouth5_ffe276f169.jpg',
          hash: 'large_yarmouth5_ffe276f169',
          mime: 'image/jpeg',
          name: 'large_yarmouth5.jpg',
          path: null,
          size: 168.1,
          width: 1000,
          height: 958,
          provider_metadata: {
            public_id: 'large_yarmouth5_ffe276f169',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971477/small_yarmouth5_ffe276f169.jpg',
          hash: 'small_yarmouth5_ffe276f169',
          mime: 'image/jpeg',
          name: 'small_yarmouth5.jpg',
          path: null,
          size: 41.49,
          width: 500,
          height: 479,
          provider_metadata: {
            public_id: 'small_yarmouth5_ffe276f169',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971476/medium_yarmouth5_ffe276f169.jpg',
          hash: 'medium_yarmouth5_ffe276f169',
          mime: 'image/jpeg',
          name: 'medium_yarmouth5.jpg',
          path: null,
          size: 91.87,
          width: 750,
          height: 719,
          provider_metadata: {
            public_id: 'medium_yarmouth5_ffe276f169',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971475/thumbnail_yarmouth5_ffe276f169.jpg',
          hash: 'thumbnail_yarmouth5_ffe276f169',
          mime: 'image/jpeg',
          name: 'thumbnail_yarmouth5.jpg',
          path: null,
          size: 5.74,
          width: 163,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_yarmouth5_ffe276f169',
            resource_type: 'image',
          },
        },
      },
      hash: 'yarmouth5_ffe276f169',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 947.1,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971475/yarmouth5_ffe276f169.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'yarmouth5_ffe276f169',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:17:57.976Z',
      updated_at: '2021-01-06T22:17:58.311Z',
    },
  },
  {
    id: 12,
    description: 'Up close',
    location: 'Yarmouth',
    published_at: '2021-01-06T22:45:55.477Z',
    created_at: '2021-01-06T22:25:02.557Z',
    updated_at: '2021-01-06T22:45:55.508Z',
    Image: {
      id: 14,
      name: 'panels.jpg',
      alternativeText: '',
      caption: '',
      width: 3072,
      height: 4096,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971474/large_panels_5bcc24fad1.jpg',
          hash: 'large_panels_5bcc24fad1',
          mime: 'image/jpeg',
          name: 'large_panels.jpg',
          path: null,
          size: 237.74,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_panels_5bcc24fad1',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971475/small_panels_5bcc24fad1.jpg',
          hash: 'small_panels_5bcc24fad1',
          mime: 'image/jpeg',
          name: 'small_panels.jpg',
          path: null,
          size: 60.33,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_panels_5bcc24fad1',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971475/medium_panels_5bcc24fad1.jpg',
          hash: 'medium_panels_5bcc24fad1',
          mime: 'image/jpeg',
          name: 'medium_panels.jpg',
          path: null,
          size: 139.45,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_panels_5bcc24fad1',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971473/thumbnail_panels_5bcc24fad1.jpg',
          hash: 'thumbnail_panels_5bcc24fad1',
          mime: 'image/jpeg',
          name: 'thumbnail_panels.jpg',
          path: null,
          size: 5.17,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_panels_5bcc24fad1',
            resource_type: 'image',
          },
        },
      },
      hash: 'panels_5bcc24fad1',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 1603.03,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971473/panels_5bcc24fad1.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'panels_5bcc24fad1',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:17:55.888Z',
      updated_at: '2021-01-06T22:17:55.987Z',
    },
  },
  {
    id: 13,
    description: 'Looking good!',
    location: 'Wells',
    published_at: '2021-01-06T22:45:57.904Z',
    created_at: '2021-01-06T22:25:49.358Z',
    updated_at: '2021-01-06T22:45:57.939Z',
    Image: {
      id: 8,
      name: 'wells1.jpg',
      alternativeText: '',
      caption: '',
      width: 1295,
      height: 971,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971471/large_wells1_87fc212e94.jpg',
          hash: 'large_wells1_87fc212e94',
          mime: 'image/jpeg',
          name: 'large_wells1.jpg',
          path: null,
          size: 150.73,
          width: 1000,
          height: 750,
          provider_metadata: {
            public_id: 'large_wells1_87fc212e94',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971472/small_wells1_87fc212e94.jpg',
          hash: 'small_wells1_87fc212e94',
          mime: 'image/jpeg',
          name: 'small_wells1.jpg',
          path: null,
          size: 36.94,
          width: 500,
          height: 375,
          provider_metadata: {
            public_id: 'small_wells1_87fc212e94',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971472/medium_wells1_87fc212e94.jpg',
          hash: 'medium_wells1_87fc212e94',
          mime: 'image/jpeg',
          name: 'medium_wells1.jpg',
          path: null,
          size: 84.27,
          width: 750,
          height: 562,
          provider_metadata: {
            public_id: 'medium_wells1_87fc212e94',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971470/thumbnail_wells1_87fc212e94.jpg',
          hash: 'thumbnail_wells1_87fc212e94',
          mime: 'image/jpeg',
          name: 'thumbnail_wells1.jpg',
          path: null,
          size: 7.12,
          width: 208,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_wells1_87fc212e94',
            resource_type: 'image',
          },
        },
      },
      hash: 'wells1_87fc212e94',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 168.04,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971469/wells1_87fc212e94.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'wells1_87fc212e94',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:17:53.506Z',
      updated_at: '2021-01-06T22:17:53.577Z',
    },
  },
  {
    id: 15,
    description: null,
    location: 'Wells',
    published_at: '2021-01-06T22:46:02.447Z',
    created_at: '2021-01-06T22:26:32.683Z',
    updated_at: '2021-01-06T22:46:02.472Z',
    Image: {
      id: 17,
      name: 'wells4.jpg',
      alternativeText: '',
      caption: '',
      width: 686,
      height: 915,
      formats: {
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971476/small_wells4_c08753c086.jpg',
          hash: 'small_wells4_c08753c086',
          mime: 'image/jpeg',
          name: 'small_wells4.jpg',
          path: null,
          size: 60.28,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_wells4_c08753c086',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971476/medium_wells4_c08753c086.jpg',
          hash: 'medium_wells4_c08753c086',
          mime: 'image/jpeg',
          name: 'medium_wells4.jpg',
          path: null,
          size: 141.53,
          width: 562,
          height: 750,
          provider_metadata: {
            public_id: 'medium_wells4_c08753c086',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971475/thumbnail_wells4_c08753c086.jpg',
          hash: 'thumbnail_wells4_c08753c086',
          mime: 'image/jpeg',
          name: 'thumbnail_wells4.jpg',
          path: null,
          size: 6.41,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_wells4_c08753c086',
            resource_type: 'image',
          },
        },
      },
      hash: 'wells4_c08753c086',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 198.17,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971475/wells4_c08753c086.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'wells4_c08753c086',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:17:57.541Z',
      updated_at: '2021-01-06T22:17:57.686Z',
    },
  },
  {
    id: 16,
    description: null,
    location: 'Wells',
    published_at: '2021-01-06T22:46:04.958Z',
    created_at: '2021-01-06T22:26:48.682Z',
    updated_at: '2021-01-06T22:46:05.010Z',
    Image: {
      id: 15,
      name: 'wells5.jpg',
      alternativeText: '',
      caption: '',
      width: 686,
      height: 915,
      formats: {
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971475/small_wells5_243a5a74c9.jpg',
          hash: 'small_wells5_243a5a74c9',
          mime: 'image/jpeg',
          name: 'small_wells5.jpg',
          path: null,
          size: 52.42,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_wells5_243a5a74c9',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971475/medium_wells5_243a5a74c9.jpg',
          hash: 'medium_wells5_243a5a74c9',
          mime: 'image/jpeg',
          name: 'medium_wells5.jpg',
          path: null,
          size: 110.28,
          width: 562,
          height: 750,
          provider_metadata: {
            public_id: 'medium_wells5_243a5a74c9',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971474/thumbnail_wells5_243a5a74c9.jpg',
          hash: 'thumbnail_wells5_243a5a74c9',
          mime: 'image/jpeg',
          name: 'thumbnail_wells5.jpg',
          path: null,
          size: 5.87,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_wells5_243a5a74c9',
            resource_type: 'image',
          },
        },
      },
      hash: 'wells5_243a5a74c9',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 140.77,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971474/wells5_243a5a74c9.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'wells5_243a5a74c9',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:17:56.235Z',
      updated_at: '2021-01-06T22:17:56.322Z',
    },
  },
  {
    id: 17,
    description: 'A thing of beauty',
    location: 'Wells',
    published_at: '2021-01-06T22:46:07.637Z',
    created_at: '2021-01-06T22:27:09.961Z',
    updated_at: '2021-01-06T22:46:07.683Z',
    Image: {
      id: 10,
      name: 'wells3.jpg',
      alternativeText: '',
      caption: '',
      width: 686,
      height: 915,
      formats: {
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971473/small_wells3_628c16f104.jpg',
          hash: 'small_wells3_628c16f104',
          mime: 'image/jpeg',
          name: 'small_wells3.jpg',
          path: null,
          size: 50.61,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_wells3_628c16f104',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971472/medium_wells3_628c16f104.jpg',
          hash: 'medium_wells3_628c16f104',
          mime: 'image/jpeg',
          name: 'medium_wells3.jpg',
          path: null,
          size: 104.04,
          width: 562,
          height: 750,
          provider_metadata: {
            public_id: 'medium_wells3_628c16f104',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971471/thumbnail_wells3_628c16f104.jpg',
          hash: 'thumbnail_wells3_628c16f104',
          mime: 'image/jpeg',
          name: 'thumbnail_wells3.jpg',
          path: null,
          size: 5.66,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_wells3_628c16f104',
            resource_type: 'image',
          },
        },
      },
      hash: 'wells3_628c16f104',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 126.71,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971470/wells3_628c16f104.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'wells3_628c16f104',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:17:53.685Z',
      updated_at: '2021-01-06T22:17:53.729Z',
    },
  },
  {
    id: 18,
    description: 'Another happy customer',
    location: 'Wells',
    published_at: '2021-01-06T22:46:10.022Z',
    created_at: '2021-01-06T22:27:35.695Z',
    updated_at: '2021-01-06T22:46:10.068Z',
    Image: {
      id: 19,
      name: 'wells6.jpg',
      alternativeText: '',
      caption: '',
      width: 1695,
      height: 2260,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971480/large_wells6_94aae01dc5.jpg',
          hash: 'large_wells6_94aae01dc5',
          mime: 'image/jpeg',
          name: 'large_wells6.jpg',
          path: null,
          size: 153.95,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_wells6_94aae01dc5',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971482/small_wells6_94aae01dc5.jpg',
          hash: 'small_wells6_94aae01dc5',
          mime: 'image/jpeg',
          name: 'small_wells6.jpg',
          path: null,
          size: 41.09,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_wells6_94aae01dc5',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971481/medium_wells6_94aae01dc5.jpg',
          hash: 'medium_wells6_94aae01dc5',
          mime: 'image/jpeg',
          name: 'medium_wells6.jpg',
          path: null,
          size: 90.23,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_wells6_94aae01dc5',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971477/thumbnail_wells6_94aae01dc5.jpg',
          hash: 'thumbnail_wells6_94aae01dc5',
          mime: 'image/jpeg',
          name: 'thumbnail_wells6.jpg',
          path: null,
          size: 5.72,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_wells6_94aae01dc5',
            resource_type: 'image',
          },
        },
      },
      hash: 'wells6_94aae01dc5',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 646.9,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971476/wells6_94aae01dc5.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'wells6_94aae01dc5',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:18:02.472Z',
      updated_at: '2021-01-06T22:18:02.504Z',
    },
  },
  {
    id: 19,
    description: "Don't look down!",
    location: 'Portland',
    published_at: '2021-01-06T22:46:12.656Z',
    created_at: '2021-01-06T22:28:04.568Z',
    updated_at: '2021-01-06T22:46:12.705Z',
    Image: {
      id: 16,
      name: 'portland2.jpg',
      alternativeText: '',
      caption: '',
      width: 3072,
      height: 4096,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971475/large_portland2_d969e0ed7b.jpg',
          hash: 'large_portland2_d969e0ed7b',
          mime: 'image/jpeg',
          name: 'large_portland2.jpg',
          path: null,
          size: 252.64,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_portland2_d969e0ed7b',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971476/small_portland2_d969e0ed7b.jpg',
          hash: 'small_portland2_d969e0ed7b',
          mime: 'image/jpeg',
          name: 'small_portland2.jpg',
          path: null,
          size: 62.73,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_portland2_d969e0ed7b',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971475/medium_portland2_d969e0ed7b.jpg',
          hash: 'medium_portland2_d969e0ed7b',
          mime: 'image/jpeg',
          name: 'medium_portland2.jpg',
          path: null,
          size: 144.09,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_portland2_d969e0ed7b',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971474/thumbnail_portland2_d969e0ed7b.jpg',
          hash: 'thumbnail_portland2_d969e0ed7b',
          mime: 'image/jpeg',
          name: 'thumbnail_portland2.jpg',
          path: null,
          size: 6.93,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_portland2_d969e0ed7b',
            resource_type: 'image',
          },
        },
      },
      hash: 'portland2_d969e0ed7b',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 2464.2,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971474/portland2_d969e0ed7b.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'portland2_d969e0ed7b',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:17:56.776Z',
      updated_at: '2021-01-06T22:17:57.445Z',
    },
  },
  {
    id: 89,
    description: 'Shawns Array, Norway, Maine (1)',
    location: 'Norway, Maine',
    published_at: '2023-01-20T16:32:11.543Z',
    created_at: '2023-01-20T16:28:43.228Z',
    updated_at: '2023-01-20T16:32:11.561Z',
    Image: {
      id: 115,
      name: 'Shawns Array, Norway, Maine',
      alternativeText: 'Shawns Array, Norway, Maine',
      caption: 'Shawns Array, Norway, Maine',
      width: 1200,
      height: 1600,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231943/large_Shawns_Array_Norway_Maine_5fe9ba5c80.jpg',
          hash: 'large_Shawns_Array_Norway_Maine_5fe9ba5c80',
          mime: 'image/jpeg',
          name: 'large_Shawns Array, Norway, Maine',
          path: null,
          size: 219.6,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_Shawns_Array_Norway_Maine_5fe9ba5c80',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231944/small_Shawns_Array_Norway_Maine_5fe9ba5c80.jpg',
          hash: 'small_Shawns_Array_Norway_Maine_5fe9ba5c80',
          mime: 'image/jpeg',
          name: 'small_Shawns Array, Norway, Maine',
          path: null,
          size: 59.97,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_Shawns_Array_Norway_Maine_5fe9ba5c80',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231944/medium_Shawns_Array_Norway_Maine_5fe9ba5c80.jpg',
          hash: 'medium_Shawns_Array_Norway_Maine_5fe9ba5c80',
          mime: 'image/jpeg',
          name: 'medium_Shawns Array, Norway, Maine',
          path: null,
          size: 125.74,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_Shawns_Array_Norway_Maine_5fe9ba5c80',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231943/thumbnail_Shawns_Array_Norway_Maine_5fe9ba5c80.jpg',
          hash: 'thumbnail_Shawns_Array_Norway_Maine_5fe9ba5c80',
          mime: 'image/jpeg',
          name: 'thumbnail_Shawns Array, Norway, Maine',
          path: null,
          size: 5.88,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id:
              'thumbnail_Shawns_Array_Norway_Maine_5fe9ba5c80',
            resource_type: 'image',
          },
        },
      },
      hash: 'Shawns_Array_Norway_Maine_5fe9ba5c80',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 522.83,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231941/Shawns_Array_Norway_Maine_5fe9ba5c80.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'Shawns_Array_Norway_Maine_5fe9ba5c80',
        resource_type: 'image',
      },
      created_at: '2023-01-20T16:25:45.021Z',
      updated_at: '2023-01-20T16:25:45.032Z',
    },
  },
  {
    id: 90,
    description: 'Shawns Array - Norway, Maine 2',
    location: 'Norway, Maine',
    published_at: '2023-01-20T16:32:15.500Z',
    created_at: '2023-01-20T16:29:34.520Z',
    updated_at: '2023-01-20T16:32:15.514Z',
    Image: {
      id: 111,
      name: 'Shawns Array - Norway, Maine 2',
      alternativeText: 'Shawns Array - Norway, Maine 2',
      caption: 'Shawns Array - Norway, Maine 2',
      width: 1200,
      height: 1600,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231942/large_Shawns_Array_Norway_Maine_2_329a3733f4.jpg',
          hash: 'large_Shawns_Array_Norway_Maine_2_329a3733f4',
          mime: 'image/jpeg',
          name: 'large_Shawns Array - Norway, Maine 2',
          path: null,
          size: 213.55,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_Shawns_Array_Norway_Maine_2_329a3733f4',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231943/small_Shawns_Array_Norway_Maine_2_329a3733f4.jpg',
          hash: 'small_Shawns_Array_Norway_Maine_2_329a3733f4',
          mime: 'image/jpeg',
          name: 'small_Shawns Array - Norway, Maine 2',
          path: null,
          size: 54.94,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_Shawns_Array_Norway_Maine_2_329a3733f4',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231943/medium_Shawns_Array_Norway_Maine_2_329a3733f4.jpg',
          hash: 'medium_Shawns_Array_Norway_Maine_2_329a3733f4',
          mime: 'image/jpeg',
          name: 'medium_Shawns Array - Norway, Maine 2',
          path: null,
          size: 120.11,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id:
              'medium_Shawns_Array_Norway_Maine_2_329a3733f4',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231942/thumbnail_Shawns_Array_Norway_Maine_2_329a3733f4.jpg',
          hash: 'thumbnail_Shawns_Array_Norway_Maine_2_329a3733f4',
          mime: 'image/jpeg',
          name: 'thumbnail_Shawns Array - Norway, Maine 2',
          path: null,
          size: 5.65,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id:
              'thumbnail_Shawns_Array_Norway_Maine_2_329a3733f4',
            resource_type: 'image',
          },
        },
      },
      hash: 'Shawns_Array_Norway_Maine_2_329a3733f4',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 546.93,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231941/Shawns_Array_Norway_Maine_2_329a3733f4.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'Shawns_Array_Norway_Maine_2_329a3733f4',
        resource_type: 'image',
      },
      created_at: '2023-01-20T16:25:44.164Z',
      updated_at: '2023-01-20T16:29:09.173Z',
    },
  },
  {
    id: 91,
    description: 'Shawns Array - Norway, Maine 3',
    location: 'Norway, Maine',
    published_at: '2023-01-20T16:32:20.022Z',
    created_at: '2023-01-20T16:30:08.885Z',
    updated_at: '2023-01-20T16:32:20.053Z',
    Image: {
      id: 112,
      name: 'Shawns Array - Norway, Maine 3',
      alternativeText: 'Shawns Array - Norway, Maine 3',
      caption: 'Shawns Array - Norway, Maine 3',
      width: 1600,
      height: 1200,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231943/large_Shawns_Array_Norway_Maine_3_2580c8a3c3.jpg',
          hash: 'large_Shawns_Array_Norway_Maine_3_2580c8a3c3',
          mime: 'image/jpeg',
          name: 'large_Shawns Array - Norway, Maine 3',
          path: null,
          size: 229.31,
          width: 1000,
          height: 750,
          provider_metadata: {
            public_id: 'large_Shawns_Array_Norway_Maine_3_2580c8a3c3',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231943/small_Shawns_Array_Norway_Maine_3_2580c8a3c3.jpg',
          hash: 'small_Shawns_Array_Norway_Maine_3_2580c8a3c3',
          mime: 'image/jpeg',
          name: 'small_Shawns Array - Norway, Maine 3',
          path: null,
          size: 63.21,
          width: 500,
          height: 375,
          provider_metadata: {
            public_id: 'small_Shawns_Array_Norway_Maine_3_2580c8a3c3',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231943/medium_Shawns_Array_Norway_Maine_3_2580c8a3c3.jpg',
          hash: 'medium_Shawns_Array_Norway_Maine_3_2580c8a3c3',
          mime: 'image/jpeg',
          name: 'medium_Shawns Array - Norway, Maine 3',
          path: null,
          size: 130.95,
          width: 750,
          height: 563,
          provider_metadata: {
            public_id:
              'medium_Shawns_Array_Norway_Maine_3_2580c8a3c3',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231942/thumbnail_Shawns_Array_Norway_Maine_3_2580c8a3c3.jpg',
          hash: 'thumbnail_Shawns_Array_Norway_Maine_3_2580c8a3c3',
          mime: 'image/jpeg',
          name: 'thumbnail_Shawns Array - Norway, Maine 3',
          path: null,
          size: 12.03,
          width: 208,
          height: 156,
          provider_metadata: {
            public_id:
              'thumbnail_Shawns_Array_Norway_Maine_3_2580c8a3c3',
            resource_type: 'image',
          },
        },
      },
      hash: 'Shawns_Array_Norway_Maine_3_2580c8a3c3',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 556.6,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231941/Shawns_Array_Norway_Maine_3_2580c8a3c3.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'Shawns_Array_Norway_Maine_3_2580c8a3c3',
        resource_type: 'image',
      },
      created_at: '2023-01-20T16:25:44.223Z',
      updated_at: '2023-01-20T16:25:44.232Z',
    },
  },
  {
    id: 43,
    description: 'After',
    location: 'Southport Island, Boothbay Region, Maine',
    published_at: '2021-07-14T15:16:23.808Z',
    created_at: '2021-07-14T14:03:32.526Z',
    updated_at: '2021-07-14T15:16:23.826Z',
    Image: {
      id: 57,
      name: 'IMG_20210510_125428554.jpg',
      alternativeText: '',
      caption: '',
      width: 3072,
      height: 4096,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271260/large_IMG_20210510_125428554_c84872ff66.jpg',
          hash: 'large_IMG_20210510_125428554_c84872ff66',
          mime: 'image/jpeg',
          name: 'large_IMG_20210510_125428554.jpg',
          path: null,
          size: 151.65,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_IMG_20210510_125428554_c84872ff66',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271261/small_IMG_20210510_125428554_c84872ff66.jpg',
          hash: 'small_IMG_20210510_125428554_c84872ff66',
          mime: 'image/jpeg',
          name: 'small_IMG_20210510_125428554.jpg',
          path: null,
          size: 39.88,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_IMG_20210510_125428554_c84872ff66',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271260/medium_IMG_20210510_125428554_c84872ff66.jpg',
          hash: 'medium_IMG_20210510_125428554_c84872ff66',
          mime: 'image/jpeg',
          name: 'medium_IMG_20210510_125428554.jpg',
          path: null,
          size: 88.41,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_IMG_20210510_125428554_c84872ff66',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271259/thumbnail_IMG_20210510_125428554_c84872ff66.jpg',
          hash: 'thumbnail_IMG_20210510_125428554_c84872ff66',
          mime: 'image/jpeg',
          name: 'thumbnail_IMG_20210510_125428554.jpg',
          path: null,
          size: 5.08,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_IMG_20210510_125428554_c84872ff66',
            resource_type: 'image',
          },
        },
      },
      hash: 'IMG_20210510_125428554_c84872ff66',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 2146.54,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271258/IMG_20210510_125428554_c84872ff66.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'IMG_20210510_125428554_c84872ff66',
        resource_type: 'image',
      },
      created_at: '2021-07-14T14:01:01.589Z',
      updated_at: '2021-07-14T14:01:01.598Z',
    },
  },
  {
    id: 44,
    description:
      '4.875 kW(DC) worth of clean solar, "Powering Up," Flag',
    location: 'Southport Island, Boothbay Region, Maine',
    published_at: '2021-07-14T15:16:29.484Z',
    created_at: '2021-07-14T14:05:38.320Z',
    updated_at: '2021-07-14T15:16:29.501Z',
    Image: {
      id: 56,
      name: 'IMG_20210510_125512303.jpg',
      alternativeText: '',
      caption: '',
      width: 3072,
      height: 4096,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271258/large_IMG_20210510_125512303_84630a666b.jpg',
          hash: 'large_IMG_20210510_125512303_84630a666b',
          mime: 'image/jpeg',
          name: 'large_IMG_20210510_125512303.jpg',
          path: null,
          size: 147.64,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_IMG_20210510_125512303_84630a666b',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271259/small_IMG_20210510_125512303_84630a666b.jpg',
          hash: 'small_IMG_20210510_125512303_84630a666b',
          mime: 'image/jpeg',
          name: 'small_IMG_20210510_125512303.jpg',
          path: null,
          size: 38.6,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_IMG_20210510_125512303_84630a666b',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271259/medium_IMG_20210510_125512303_84630a666b.jpg',
          hash: 'medium_IMG_20210510_125512303_84630a666b',
          mime: 'image/jpeg',
          name: 'medium_IMG_20210510_125512303.jpg',
          path: null,
          size: 85.66,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_IMG_20210510_125512303_84630a666b',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271258/thumbnail_IMG_20210510_125512303_84630a666b.jpg',
          hash: 'thumbnail_IMG_20210510_125512303_84630a666b',
          mime: 'image/jpeg',
          name: 'thumbnail_IMG_20210510_125512303.jpg',
          path: null,
          size: 5.11,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_IMG_20210510_125512303_84630a666b',
            resource_type: 'image',
          },
        },
      },
      hash: 'IMG_20210510_125512303_84630a666b',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 2153.55,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271257/IMG_20210510_125512303_84630a666b.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'IMG_20210510_125512303_84630a666b',
        resource_type: 'image',
      },
      created_at: '2021-07-14T14:01:00.579Z',
      updated_at: '2021-07-14T14:01:00.592Z',
    },
  },
  {
    id: 45,
    description:
      '4.875 kW(DC), Energizing Home & Town Landing Boathouse',
    location: 'Southport Island, Boothbay Region, Maine',
    published_at: '2021-07-14T15:16:34.121Z',
    created_at: '2021-07-14T14:06:58.061Z',
    updated_at: '2021-07-14T15:16:34.135Z',
    Image: {
      id: 55,
      name: 'IMG_20210510_125532036.jpg',
      alternativeText: '',
      caption: '',
      width: 3376,
      height: 1792,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271218/large_IMG_20210510_125532036_e35853a6f9.jpg',
          hash: 'large_IMG_20210510_125532036_e35853a6f9',
          mime: 'image/jpeg',
          name: 'large_IMG_20210510_125532036.jpg',
          path: null,
          size: 122.87,
          width: 1000,
          height: 531,
          provider_metadata: {
            public_id: 'large_IMG_20210510_125532036_e35853a6f9',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271220/small_IMG_20210510_125532036_e35853a6f9.jpg',
          hash: 'small_IMG_20210510_125532036_e35853a6f9',
          mime: 'image/jpeg',
          name: 'small_IMG_20210510_125532036.jpg',
          path: null,
          size: 30.6,
          width: 500,
          height: 265,
          provider_metadata: {
            public_id: 'small_IMG_20210510_125532036_e35853a6f9',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271219/medium_IMG_20210510_125532036_e35853a6f9.jpg',
          hash: 'medium_IMG_20210510_125532036_e35853a6f9',
          mime: 'image/jpeg',
          name: 'medium_IMG_20210510_125532036.jpg',
          path: null,
          size: 65.96,
          width: 750,
          height: 398,
          provider_metadata: {
            public_id: 'medium_IMG_20210510_125532036_e35853a6f9',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271218/thumbnail_IMG_20210510_125532036_e35853a6f9.jpg',
          hash: 'thumbnail_IMG_20210510_125532036_e35853a6f9',
          mime: 'image/jpeg',
          name: 'thumbnail_IMG_20210510_125532036.jpg',
          path: null,
          size: 8.3,
          width: 245,
          height: 130,
          provider_metadata: {
            public_id: 'thumbnail_IMG_20210510_125532036_e35853a6f9',
            resource_type: 'image',
          },
        },
      },
      hash: 'IMG_20210510_125532036_e35853a6f9',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 1171.53,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271217/IMG_20210510_125532036_e35853a6f9.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'IMG_20210510_125532036_e35853a6f9',
        resource_type: 'image',
      },
      created_at: '2021-07-14T14:00:20.498Z',
      updated_at: '2021-07-14T14:00:20.507Z',
    },
  },
  {
    id: 42,
    description: 'Before',
    location: 'Southport Island, Boothbay Region, Maine',
    published_at: '2021-07-14T15:16:43.082Z',
    created_at: '2021-07-14T14:02:10.451Z',
    updated_at: '2021-07-14T15:16:43.096Z',
    Image: {
      id: 54,
      name: 'IMG_20210413_112352003.jpg',
      alternativeText: '',
      caption: '',
      width: 1200,
      height: 1600,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271211/large_IMG_20210413_112352003_7d4afa8eaa.jpg',
          hash: 'large_IMG_20210413_112352003_7d4afa8eaa',
          mime: 'image/jpeg',
          name: 'large_IMG_20210413_112352003.jpg',
          path: null,
          size: 121.16,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_IMG_20210413_112352003_7d4afa8eaa',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271212/small_IMG_20210413_112352003_7d4afa8eaa.jpg',
          hash: 'small_IMG_20210413_112352003_7d4afa8eaa',
          mime: 'image/jpeg',
          name: 'small_IMG_20210413_112352003.jpg',
          path: null,
          size: 33.31,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_IMG_20210413_112352003_7d4afa8eaa',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271211/medium_IMG_20210413_112352003_7d4afa8eaa.jpg',
          hash: 'medium_IMG_20210413_112352003_7d4afa8eaa',
          mime: 'image/jpeg',
          name: 'medium_IMG_20210413_112352003.jpg',
          path: null,
          size: 69.25,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_IMG_20210413_112352003_7d4afa8eaa',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271210/thumbnail_IMG_20210413_112352003_7d4afa8eaa.jpg',
          hash: 'thumbnail_IMG_20210413_112352003_7d4afa8eaa',
          mime: 'image/jpeg',
          name: 'thumbnail_IMG_20210413_112352003.jpg',
          path: null,
          size: 4.18,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_IMG_20210413_112352003_7d4afa8eaa',
            resource_type: 'image',
          },
        },
      },
      hash: 'IMG_20210413_112352003_7d4afa8eaa',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 298,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1626271209/IMG_20210413_112352003_7d4afa8eaa.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'IMG_20210413_112352003_7d4afa8eaa',
        resource_type: 'image',
      },
      created_at: '2021-07-14T14:00:12.362Z',
      updated_at: '2021-07-14T14:00:12.375Z',
    },
  },
  {
    id: 92,
    description: 'Shawns Array - Norway, Maine 4',
    location: 'Norway, Maine',
    published_at: '2023-01-20T16:32:23.813Z',
    created_at: '2023-01-20T16:30:41.213Z',
    updated_at: '2023-01-20T16:32:23.850Z',
    Image: {
      id: 114,
      name: 'Shawns Array - Norway, Maine 4',
      alternativeText: 'Shawns Array - Norway, Maine 4',
      caption: 'Shawns Array - Norway, Maine 4',
      width: 1600,
      height: 1200,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231943/large_Shawns_Array_Norway_Maine_4_a88507e23e.jpg',
          hash: 'large_Shawns_Array_Norway_Maine_4_a88507e23e',
          mime: 'image/jpeg',
          name: 'large_Shawns Array - Norway, Maine 4',
          path: null,
          size: 236.63,
          width: 1000,
          height: 750,
          provider_metadata: {
            public_id: 'large_Shawns_Array_Norway_Maine_4_a88507e23e',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231944/small_Shawns_Array_Norway_Maine_4_a88507e23e.jpg',
          hash: 'small_Shawns_Array_Norway_Maine_4_a88507e23e',
          mime: 'image/jpeg',
          name: 'small_Shawns Array - Norway, Maine 4',
          path: null,
          size: 64.13,
          width: 500,
          height: 375,
          provider_metadata: {
            public_id: 'small_Shawns_Array_Norway_Maine_4_a88507e23e',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231943/medium_Shawns_Array_Norway_Maine_4_a88507e23e.jpg',
          hash: 'medium_Shawns_Array_Norway_Maine_4_a88507e23e',
          mime: 'image/jpeg',
          name: 'medium_Shawns Array - Norway, Maine 4',
          path: null,
          size: 134,
          width: 750,
          height: 563,
          provider_metadata: {
            public_id:
              'medium_Shawns_Array_Norway_Maine_4_a88507e23e',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231942/thumbnail_Shawns_Array_Norway_Maine_4_a88507e23e.jpg',
          hash: 'thumbnail_Shawns_Array_Norway_Maine_4_a88507e23e',
          mime: 'image/jpeg',
          name: 'thumbnail_Shawns Array - Norway, Maine 4',
          path: null,
          size: 11.89,
          width: 208,
          height: 156,
          provider_metadata: {
            public_id:
              'thumbnail_Shawns_Array_Norway_Maine_4_a88507e23e',
            resource_type: 'image',
          },
        },
      },
      hash: 'Shawns_Array_Norway_Maine_4_a88507e23e',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 586.67,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231941/Shawns_Array_Norway_Maine_4_a88507e23e.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'Shawns_Array_Norway_Maine_4_a88507e23e',
        resource_type: 'image',
      },
      created_at: '2023-01-20T16:25:44.393Z',
      updated_at: '2023-01-20T16:25:44.409Z',
    },
  },
  {
    id: 14,
    description: 'Littlefield Rd',
    location: 'Wells',
    published_at: '2021-01-06T22:45:59.893Z',
    created_at: '2021-01-06T22:26:09.172Z',
    updated_at: '2022-02-03T22:09:59.036Z',
    Image: {
      id: 11,
      name: 'wells2.jpg',
      alternativeText: '',
      caption: '',
      width: 971,
      height: 1295,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971472/large_wells2_f11ea60a04.jpg',
          hash: 'large_wells2_f11ea60a04',
          mime: 'image/jpeg',
          name: 'large_wells2.jpg',
          path: null,
          size: 168.4,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_wells2_f11ea60a04',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971473/small_wells2_f11ea60a04.jpg',
          hash: 'small_wells2_f11ea60a04',
          mime: 'image/jpeg',
          name: 'small_wells2.jpg',
          path: null,
          size: 41.07,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_wells2_f11ea60a04',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971473/medium_wells2_f11ea60a04.jpg',
          hash: 'medium_wells2_f11ea60a04',
          mime: 'image/jpeg',
          name: 'medium_wells2.jpg',
          path: null,
          size: 94.62,
          width: 562,
          height: 750,
          provider_metadata: {
            public_id: 'medium_wells2_f11ea60a04',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971471/thumbnail_wells2_f11ea60a04.jpg',
          hash: 'thumbnail_wells2_f11ea60a04',
          mime: 'image/jpeg',
          name: 'thumbnail_wells2.jpg',
          path: null,
          size: 5.11,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_wells2_f11ea60a04',
            resource_type: 'image',
          },
        },
      },
      hash: 'wells2_f11ea60a04',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 193.88,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1609971470/wells2_f11ea60a04.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'wells2_f11ea60a04',
        resource_type: 'image',
      },
      created_at: '2021-01-06T22:17:54.083Z',
      updated_at: '2021-01-06T22:17:54.161Z',
    },
  },
  {
    id: 82,
    description: "Scoping out, Dave & Karen's Roof",
    location: 'Standish, Maine',
    published_at: '2023-01-20T16:16:09.980Z',
    created_at: '2023-01-20T16:09:03.675Z',
    updated_at: '2023-01-20T16:16:09.996Z',
    Image: {
      id: 109,
      name: 'Dave And Karen (Before)',
      alternativeText: 'Dave And Karen (Before)',
      caption: 'Dave And Karen (Before)',
      width: 3120,
      height: 4160,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230763/large_Dave_And_Karen_Before_1753a44cb6.jpg',
          hash: 'large_Dave_And_Karen_Before_1753a44cb6',
          mime: 'image/jpeg',
          name: 'large_Dave And Karen (Before)',
          path: null,
          size: 185.36,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_Dave_And_Karen_Before_1753a44cb6',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230764/small_Dave_And_Karen_Before_1753a44cb6.jpg',
          hash: 'small_Dave_And_Karen_Before_1753a44cb6',
          mime: 'image/jpeg',
          name: 'small_Dave And Karen (Before)',
          path: null,
          size: 49.45,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_Dave_And_Karen_Before_1753a44cb6',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230763/medium_Dave_And_Karen_Before_1753a44cb6.jpg',
          hash: 'medium_Dave_And_Karen_Before_1753a44cb6',
          mime: 'image/jpeg',
          name: 'medium_Dave And Karen (Before)',
          path: null,
          size: 109.97,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_Dave_And_Karen_Before_1753a44cb6',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230762/thumbnail_Dave_And_Karen_Before_1753a44cb6.jpg',
          hash: 'thumbnail_Dave_And_Karen_Before_1753a44cb6',
          mime: 'image/jpeg',
          name: 'thumbnail_Dave And Karen (Before)',
          path: null,
          size: 6.07,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_Dave_And_Karen_Before_1753a44cb6',
            resource_type: 'image',
          },
        },
      },
      hash: 'Dave_And_Karen_Before_1753a44cb6',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 2408.31,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230762/Dave_And_Karen_Before_1753a44cb6.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'Dave_And_Karen_Before_1753a44cb6',
        resource_type: 'image',
      },
      created_at: '2023-01-20T16:06:04.421Z',
      updated_at: '2023-01-20T16:06:04.432Z',
    },
  },
  {
    id: 83,
    description: 'Racking up in Standish',
    location: 'Standish, Maine',
    published_at: '2023-01-20T16:16:16.092Z',
    created_at: '2023-01-20T16:10:46.385Z',
    updated_at: '2023-01-20T16:16:16.106Z',
    Image: {
      id: 106,
      name: 'Dave and Karen 3',
      alternativeText: 'Dave and Karen 3',
      caption: 'Dave and Karen 3',
      width: 1200,
      height: 1600,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230762/large_Dave_and_Karen_3_5a635cd950.jpg',
          hash: 'large_Dave_and_Karen_3_5a635cd950',
          mime: 'image/jpeg',
          name: 'large_Dave and Karen 3',
          path: null,
          size: 258.87,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_Dave_and_Karen_3_5a635cd950',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230763/small_Dave_and_Karen_3_5a635cd950.jpg',
          hash: 'small_Dave_and_Karen_3_5a635cd950',
          mime: 'image/jpeg',
          name: 'small_Dave and Karen 3',
          path: null,
          size: 70.17,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_Dave_and_Karen_3_5a635cd950',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230763/medium_Dave_and_Karen_3_5a635cd950.jpg',
          hash: 'medium_Dave_and_Karen_3_5a635cd950',
          mime: 'image/jpeg',
          name: 'medium_Dave and Karen 3',
          path: null,
          size: 146.98,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_Dave_and_Karen_3_5a635cd950',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230761/thumbnail_Dave_and_Karen_3_5a635cd950.jpg',
          hash: 'thumbnail_Dave_and_Karen_3_5a635cd950',
          mime: 'image/jpeg',
          name: 'thumbnail_Dave and Karen 3',
          path: null,
          size: 7.54,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_Dave_and_Karen_3_5a635cd950',
            resource_type: 'image',
          },
        },
      },
      hash: 'Dave_and_Karen_3_5a635cd950',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 628.11,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230761/Dave_and_Karen_3_5a635cd950.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'Dave_and_Karen_3_5a635cd950',
        resource_type: 'image',
      },
      created_at: '2023-01-20T16:06:04.015Z',
      updated_at: '2023-01-20T16:06:04.037Z',
    },
  },
  {
    id: 84,
    description: 'Flashed and Racked up in Standish(1)',
    location: 'Standish, Maine',
    published_at: '2023-01-20T16:16:21.075Z',
    created_at: '2023-01-20T16:11:31.030Z',
    updated_at: '2023-01-20T16:16:21.099Z',
    Image: {
      id: 107,
      name: 'Dave and Karen 2',
      alternativeText: 'Dave and Karen 2',
      caption: 'Dave and Karen 2',
      width: 1200,
      height: 1600,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230762/large_Dave_and_Karen_2_31f6075764.jpg',
          hash: 'large_Dave_and_Karen_2_31f6075764',
          mime: 'image/jpeg',
          name: 'large_Dave and Karen 2',
          path: null,
          size: 168.25,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_Dave_and_Karen_2_31f6075764',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230763/small_Dave_and_Karen_2_31f6075764.jpg',
          hash: 'small_Dave_and_Karen_2_31f6075764',
          mime: 'image/jpeg',
          name: 'small_Dave and Karen 2',
          path: null,
          size: 50.45,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_Dave_and_Karen_2_31f6075764',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230763/medium_Dave_and_Karen_2_31f6075764.jpg',
          hash: 'medium_Dave_and_Karen_2_31f6075764',
          mime: 'image/jpeg',
          name: 'medium_Dave and Karen 2',
          path: null,
          size: 99.92,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_Dave_and_Karen_2_31f6075764',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230761/thumbnail_Dave_and_Karen_2_31f6075764.jpg',
          hash: 'thumbnail_Dave_and_Karen_2_31f6075764',
          mime: 'image/jpeg',
          name: 'thumbnail_Dave and Karen 2',
          path: null,
          size: 6.05,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_Dave_and_Karen_2_31f6075764',
            resource_type: 'image',
          },
        },
      },
      hash: 'Dave_and_Karen_2_31f6075764',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 389.28,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230761/Dave_and_Karen_2_31f6075764.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'Dave_and_Karen_2_31f6075764',
        resource_type: 'image',
      },
      created_at: '2023-01-20T16:06:04.219Z',
      updated_at: '2023-01-20T16:06:04.228Z',
    },
  },
  {
    id: 75,
    description: 'Hersey Street 1',
    location: 'Portland',
    published_at: '2022-02-03T22:47:41.047Z',
    created_at: '2022-02-03T22:37:47.754Z',
    updated_at: '2022-02-03T22:47:41.069Z',
    Image: {
      id: 94,
      name: 'IMG_20220124_140208532_HDR.jpg',
      alternativeText: '',
      caption: '',
      width: 3072,
      height: 4096,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1643926738/large_IMG_20220124_140208532_HDR_826f5c7bb3.jpg',
          hash: 'large_IMG_20220124_140208532_HDR_826f5c7bb3',
          mime: 'image/jpeg',
          name: 'large_IMG_20220124_140208532_HDR.jpg',
          path: null,
          size: 85.79,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_IMG_20220124_140208532_HDR_826f5c7bb3',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1643926738/small_IMG_20220124_140208532_HDR_826f5c7bb3.jpg',
          hash: 'small_IMG_20220124_140208532_HDR_826f5c7bb3',
          mime: 'image/jpeg',
          name: 'small_IMG_20220124_140208532_HDR.jpg',
          path: null,
          size: 25.93,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_IMG_20220124_140208532_HDR_826f5c7bb3',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1643926738/medium_IMG_20220124_140208532_HDR_826f5c7bb3.jpg',
          hash: 'medium_IMG_20220124_140208532_HDR_826f5c7bb3',
          mime: 'image/jpeg',
          name: 'medium_IMG_20220124_140208532_HDR.jpg',
          path: null,
          size: 52.6,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_IMG_20220124_140208532_HDR_826f5c7bb3',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1643926737/thumbnail_IMG_20220124_140208532_HDR_826f5c7bb3.jpg',
          hash: 'thumbnail_IMG_20220124_140208532_HDR_826f5c7bb3',
          mime: 'image/jpeg',
          name: 'thumbnail_IMG_20220124_140208532_HDR.jpg',
          path: null,
          size: 4.19,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id:
              'thumbnail_IMG_20220124_140208532_HDR_826f5c7bb3',
            resource_type: 'image',
          },
        },
      },
      hash: 'IMG_20220124_140208532_HDR_826f5c7bb3',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 1095,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1643926737/IMG_20220124_140208532_HDR_826f5c7bb3.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'IMG_20220124_140208532_HDR_826f5c7bb3',
        resource_type: 'image',
      },
      created_at: '2022-02-03T22:18:59.352Z',
      updated_at: '2022-02-03T22:18:59.364Z',
    },
  },
  {
    id: 76,
    description: 'Hersey Street 2',
    location: 'Portland',
    published_at: '2022-02-03T22:47:48.086Z',
    created_at: '2022-02-03T22:38:43.357Z',
    updated_at: '2022-02-03T22:47:48.101Z',
    Image: {
      id: 95,
      name: 'IMG_20220124_140215911_HDR.jpg',
      alternativeText: '',
      caption: '',
      width: 3072,
      height: 4096,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1643926760/large_IMG_20220124_140215911_HDR_179de2079e.jpg',
          hash: 'large_IMG_20220124_140215911_HDR_179de2079e',
          mime: 'image/jpeg',
          name: 'large_IMG_20220124_140215911_HDR.jpg',
          path: null,
          size: 88.89,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_IMG_20220124_140215911_HDR_179de2079e',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1643926761/small_IMG_20220124_140215911_HDR_179de2079e.jpg',
          hash: 'small_IMG_20220124_140215911_HDR_179de2079e',
          mime: 'image/jpeg',
          name: 'small_IMG_20220124_140215911_HDR.jpg',
          path: null,
          size: 26.74,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_IMG_20220124_140215911_HDR_179de2079e',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1643926761/medium_IMG_20220124_140215911_HDR_179de2079e.jpg',
          hash: 'medium_IMG_20220124_140215911_HDR_179de2079e',
          mime: 'image/jpeg',
          name: 'medium_IMG_20220124_140215911_HDR.jpg',
          path: null,
          size: 54.5,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_IMG_20220124_140215911_HDR_179de2079e',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1643926760/thumbnail_IMG_20220124_140215911_HDR_179de2079e.jpg',
          hash: 'thumbnail_IMG_20220124_140215911_HDR_179de2079e',
          mime: 'image/jpeg',
          name: 'thumbnail_IMG_20220124_140215911_HDR.jpg',
          path: null,
          size: 4.41,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id:
              'thumbnail_IMG_20220124_140215911_HDR_179de2079e',
            resource_type: 'image',
          },
        },
      },
      hash: 'IMG_20220124_140215911_HDR_179de2079e',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 1133.73,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1643926760/IMG_20220124_140215911_HDR_179de2079e.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'IMG_20220124_140215911_HDR_179de2079e',
        resource_type: 'image',
      },
      created_at: '2022-02-03T22:19:22.173Z',
      updated_at: '2022-02-03T22:19:22.181Z',
    },
  },
  {
    id: 77,
    description: 'Hersey Street 3',
    location: 'Portland',
    published_at: '2022-02-03T22:47:53.921Z',
    created_at: '2022-02-03T22:46:14.580Z',
    updated_at: '2022-02-03T22:47:53.940Z',
    Image: {
      id: 96,
      name: 'IMG_20220124_145909615_HDR.jpg',
      alternativeText: '',
      caption: '',
      width: 3072,
      height: 4096,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1643928014/large_IMG_20220124_145909615_HDR_9f65ced0f7.jpg',
          hash: 'large_IMG_20220124_145909615_HDR_9f65ced0f7',
          mime: 'image/jpeg',
          name: 'large_IMG_20220124_145909615_HDR.jpg',
          path: null,
          size: 137.16,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_IMG_20220124_145909615_HDR_9f65ced0f7',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1643928015/small_IMG_20220124_145909615_HDR_9f65ced0f7.jpg',
          hash: 'small_IMG_20220124_145909615_HDR_9f65ced0f7',
          mime: 'image/jpeg',
          name: 'small_IMG_20220124_145909615_HDR.jpg',
          path: null,
          size: 35.38,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_IMG_20220124_145909615_HDR_9f65ced0f7',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1643928015/medium_IMG_20220124_145909615_HDR_9f65ced0f7.jpg',
          hash: 'medium_IMG_20220124_145909615_HDR_9f65ced0f7',
          mime: 'image/jpeg',
          name: 'medium_IMG_20220124_145909615_HDR.jpg',
          path: null,
          size: 79.84,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_IMG_20220124_145909615_HDR_9f65ced0f7',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1643928014/thumbnail_IMG_20220124_145909615_HDR_9f65ced0f7.jpg',
          hash: 'thumbnail_IMG_20220124_145909615_HDR_9f65ced0f7',
          mime: 'image/jpeg',
          name: 'thumbnail_IMG_20220124_145909615_HDR.jpg',
          path: null,
          size: 4.45,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id:
              'thumbnail_IMG_20220124_145909615_HDR_9f65ced0f7',
            resource_type: 'image',
          },
        },
      },
      hash: 'IMG_20220124_145909615_HDR_9f65ced0f7',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 1967.51,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1643928013/IMG_20220124_145909615_HDR_9f65ced0f7.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'IMG_20220124_145909615_HDR_9f65ced0f7',
        resource_type: 'image',
      },
      created_at: '2022-02-03T22:40:15.888Z',
      updated_at: '2022-02-03T22:40:15.901Z',
    },
  },
  {
    id: 85,
    description: 'Mods going up...',
    location: 'Standish, Maine',
    published_at: '2023-01-20T16:16:25.832Z',
    created_at: '2023-01-20T16:13:07.623Z',
    updated_at: '2023-01-20T16:16:25.855Z',
    Image: {
      id: 105,
      name: 'Dave and Karen 4',
      alternativeText: 'Dave and Karen 4',
      caption: 'Dave and Karen 4',
      width: 1200,
      height: 1600,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230762/large_Dave_and_Karen_4_d325575246.jpg',
          hash: 'large_Dave_and_Karen_4_d325575246',
          mime: 'image/jpeg',
          name: 'large_Dave and Karen 4',
          path: null,
          size: 228.81,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_Dave_and_Karen_4_d325575246',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230763/small_Dave_and_Karen_4_d325575246.jpg',
          hash: 'small_Dave_and_Karen_4_d325575246',
          mime: 'image/jpeg',
          name: 'small_Dave and Karen 4',
          path: null,
          size: 62.93,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_Dave_and_Karen_4_d325575246',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230763/medium_Dave_and_Karen_4_d325575246.jpg',
          hash: 'medium_Dave_and_Karen_4_d325575246',
          mime: 'image/jpeg',
          name: 'medium_Dave and Karen 4',
          path: null,
          size: 130.2,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_Dave_and_Karen_4_d325575246',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230761/thumbnail_Dave_and_Karen_4_d325575246.jpg',
          hash: 'thumbnail_Dave_and_Karen_4_d325575246',
          mime: 'image/jpeg',
          name: 'thumbnail_Dave and Karen 4',
          path: null,
          size: 6.9,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_Dave_and_Karen_4_d325575246',
            resource_type: 'image',
          },
        },
      },
      hash: 'Dave_and_Karen_4_d325575246',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 546.33,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230761/Dave_and_Karen_4_d325575246.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'Dave_and_Karen_4_d325575246',
        resource_type: 'image',
      },
      created_at: '2023-01-20T16:06:03.990Z',
      updated_at: '2023-01-20T16:06:04.011Z',
    },
  },
  {
    id: 86,
    description: '8.5 KW of Clean Solar Power, Made in the USA',
    location: 'Standish, Maine',
    published_at: '2023-01-20T16:16:29.590Z',
    created_at: '2023-01-20T16:15:13.170Z',
    updated_at: '2023-01-20T16:16:29.605Z',
    Image: {
      id: 108,
      name: 'Dave & Karen (After)',
      alternativeText: 'Dave & Karen (After)',
      caption: 'Dave & Karen (After)',
      width: 1200,
      height: 1600,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230762/large_Dave_and_Karen_After_b08d5bec9b.jpg',
          hash: 'large_Dave_and_Karen_After_b08d5bec9b',
          mime: 'image/jpeg',
          name: 'large_Dave & Karen (After)',
          path: null,
          size: 204.03,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_Dave_and_Karen_After_b08d5bec9b',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230763/small_Dave_and_Karen_After_b08d5bec9b.jpg',
          hash: 'small_Dave_and_Karen_After_b08d5bec9b',
          mime: 'image/jpeg',
          name: 'small_Dave & Karen (After)',
          path: null,
          size: 61.87,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_Dave_and_Karen_After_b08d5bec9b',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230763/medium_Dave_and_Karen_After_b08d5bec9b.jpg',
          hash: 'medium_Dave_and_Karen_After_b08d5bec9b',
          mime: 'image/jpeg',
          name: 'medium_Dave & Karen (After)',
          path: null,
          size: 121.93,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id: 'medium_Dave_and_Karen_After_b08d5bec9b',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230761/thumbnail_Dave_and_Karen_After_b08d5bec9b.jpg',
          hash: 'thumbnail_Dave_and_Karen_After_b08d5bec9b',
          mime: 'image/jpeg',
          name: 'thumbnail_Dave & Karen (After)',
          path: null,
          size: 7.17,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id: 'thumbnail_Dave_and_Karen_After_b08d5bec9b',
            resource_type: 'image',
          },
        },
      },
      hash: 'Dave_and_Karen_After_b08d5bec9b',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 467.96,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1674230761/Dave_and_Karen_After_b08d5bec9b.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'Dave_and_Karen_After_b08d5bec9b',
        resource_type: 'image',
      },
      created_at: '2023-01-20T16:06:04.306Z',
      updated_at: '2023-01-20T16:06:04.315Z',
    },
  },
  {
    id: 93,
    description: 'Shawns Array - Norway, Maine 5',
    location: 'Norway, Maine',
    published_at: '2023-01-20T16:32:27.748Z',
    created_at: '2023-01-20T16:31:03.472Z',
    updated_at: '2023-01-20T16:32:27.764Z',
    Image: {
      id: 116,
      name: 'Shawns Array - Norway, Maine 5',
      alternativeText: 'Shawns Array - Norway, Maine 5',
      caption: 'Shawns Array - Norway, Maine 5',
      width: 3120,
      height: 4160,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231947/large_Shawns_Array_Norway_Maine_5_e8bc1acea3.jpg',
          hash: 'large_Shawns_Array_Norway_Maine_5_e8bc1acea3',
          mime: 'image/jpeg',
          name: 'large_Shawns Array - Norway, Maine 5',
          path: null,
          size: 158.99,
          width: 750,
          height: 1000,
          provider_metadata: {
            public_id: 'large_Shawns_Array_Norway_Maine_5_e8bc1acea3',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231948/small_Shawns_Array_Norway_Maine_5_e8bc1acea3.jpg',
          hash: 'small_Shawns_Array_Norway_Maine_5_e8bc1acea3',
          mime: 'image/jpeg',
          name: 'small_Shawns Array - Norway, Maine 5',
          path: null,
          size: 43.69,
          width: 375,
          height: 500,
          provider_metadata: {
            public_id: 'small_Shawns_Array_Norway_Maine_5_e8bc1acea3',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231947/medium_Shawns_Array_Norway_Maine_5_e8bc1acea3.jpg',
          hash: 'medium_Shawns_Array_Norway_Maine_5_e8bc1acea3',
          mime: 'image/jpeg',
          name: 'medium_Shawns Array - Norway, Maine 5',
          path: null,
          size: 95.51,
          width: 563,
          height: 750,
          provider_metadata: {
            public_id:
              'medium_Shawns_Array_Norway_Maine_5_e8bc1acea3',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231946/thumbnail_Shawns_Array_Norway_Maine_5_e8bc1acea3.jpg',
          hash: 'thumbnail_Shawns_Array_Norway_Maine_5_e8bc1acea3',
          mime: 'image/jpeg',
          name: 'thumbnail_Shawns Array - Norway, Maine 5',
          path: null,
          size: 5.42,
          width: 117,
          height: 156,
          provider_metadata: {
            public_id:
              'thumbnail_Shawns_Array_Norway_Maine_5_e8bc1acea3',
            resource_type: 'image',
          },
        },
      },
      hash: 'Shawns_Array_Norway_Maine_5_e8bc1acea3',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 2166.26,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231946/Shawns_Array_Norway_Maine_5_e8bc1acea3.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'Shawns_Array_Norway_Maine_5_e8bc1acea3',
        resource_type: 'image',
      },
      created_at: '2023-01-20T16:25:48.731Z',
      updated_at: '2023-01-20T16:25:48.740Z',
    },
  },
  {
    id: 94,
    description:
      'Sunset, Finished product on another 11.1kW of clean solar energy in Norway Maine',
    location: 'Norway, Maine',
    published_at: '2023-01-20T16:32:32.758Z',
    created_at: '2023-01-20T16:31:42.752Z',
    updated_at: '2023-01-20T16:32:32.788Z',
    Image: {
      id: 117,
      name: 'Sunset, Finished product on another 11.1kW of clean solar energy in Norway Maine',
      alternativeText:
        'Sunset, Finished product on another 11.1kW of clean solar energy in Norway Maine',
      caption:
        'Sunset, Finished product on another 11.1kW of clean solar energy in Norway Maine',
      width: 4160,
      height: 3120,
      formats: {
        large: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231947/large_Sunset_Finished_product_on_another_11_1k_W_of_clean_solar_energy_in_Norway_Maine_abb6860158.jpg',
          hash: 'large_Sunset_Finished_product_on_another_11_1k_W_of_clean_solar_energy_in_Norway_Maine_abb6860158',
          mime: 'image/jpeg',
          name: 'large_Sunset, Finished product on another 11.1kW of clean solar energy in Norway Maine',
          path: null,
          size: 174.1,
          width: 1000,
          height: 750,
          provider_metadata: {
            public_id:
              'large_Sunset_Finished_product_on_another_11_1k_W_of_clean_solar_energy_in_Norway_Maine_abb6860158',
            resource_type: 'image',
          },
        },
        small: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231948/small_Sunset_Finished_product_on_another_11_1k_W_of_clean_solar_energy_in_Norway_Maine_abb6860158.jpg',
          hash: 'small_Sunset_Finished_product_on_another_11_1k_W_of_clean_solar_energy_in_Norway_Maine_abb6860158',
          mime: 'image/jpeg',
          name: 'small_Sunset, Finished product on another 11.1kW of clean solar energy in Norway Maine',
          path: null,
          size: 47.13,
          width: 500,
          height: 375,
          provider_metadata: {
            public_id:
              'small_Sunset_Finished_product_on_another_11_1k_W_of_clean_solar_energy_in_Norway_Maine_abb6860158',
            resource_type: 'image',
          },
        },
        medium: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231948/medium_Sunset_Finished_product_on_another_11_1k_W_of_clean_solar_energy_in_Norway_Maine_abb6860158.jpg',
          hash: 'medium_Sunset_Finished_product_on_another_11_1k_W_of_clean_solar_energy_in_Norway_Maine_abb6860158',
          mime: 'image/jpeg',
          name: 'medium_Sunset, Finished product on another 11.1kW of clean solar energy in Norway Maine',
          path: null,
          size: 103.1,
          width: 750,
          height: 563,
          provider_metadata: {
            public_id:
              'medium_Sunset_Finished_product_on_another_11_1k_W_of_clean_solar_energy_in_Norway_Maine_abb6860158',
            resource_type: 'image',
          },
        },
        thumbnail: {
          ext: '.jpg',
          url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231947/thumbnail_Sunset_Finished_product_on_another_11_1k_W_of_clean_solar_energy_in_Norway_Maine_abb6860158.jpg',
          hash: 'thumbnail_Sunset_Finished_product_on_another_11_1k_W_of_clean_solar_energy_in_Norway_Maine_abb6860158',
          mime: 'image/jpeg',
          name: 'thumbnail_Sunset, Finished product on another 11.1kW of clean solar energy in Norway Maine',
          path: null,
          size: 9.81,
          width: 208,
          height: 156,
          provider_metadata: {
            public_id:
              'thumbnail_Sunset_Finished_product_on_another_11_1k_W_of_clean_solar_energy_in_Norway_Maine_abb6860158',
            resource_type: 'image',
          },
        },
      },
      hash: 'Sunset_Finished_product_on_another_11_1k_W_of_clean_solar_energy_in_Norway_Maine_abb6860158',
      ext: '.jpg',
      mime: 'image/jpeg',
      size: 2708.18,
      url: 'https://res.cloudinary.com/robitaille/image/upload/v1674231946/Sunset_Finished_product_on_another_11_1k_W_of_clean_solar_energy_in_Norway_Maine_abb6860158.jpg',
      previewUrl: null,
      provider: 'cloudinary',
      provider_metadata: {
        public_id:
          'Sunset_Finished_product_on_another_11_1k_W_of_clean_solar_energy_in_Norway_Maine_abb6860158',
        resource_type: 'image',
      },
      created_at: '2023-01-20T16:25:49.040Z',
      updated_at: '2023-01-20T16:25:49.048Z',
    },
  },
];
